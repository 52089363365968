<script>
import { mapStores } from 'pinia'

import { useOrgStore } from '@/stores'
import { useFoundStore } from '../store.js'

import ItemSearch from '../components/ItemSearch.vue'
import ItemViewModal from '../modals/ItemView.vue'
import utils from '@/utils.js'

export default {
  name: 'FoundItems',

  components: {
    ItemSearch,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let org = vm.organization.org
      if (!org) return next()

      let requireActive = vm.$route.meta.requireActive
      if (requireActive && !org?.displayOptions?.isActive) {
        return next({ name: 'org-closed' })
      } else {
        return next()
      }
    })
  },

  props: {},

  data() {
    return {
      form: {
        q: null,
        category: null,
        epoch: null,
        group: null,
        location: null,
        page: 0,
      },
      isLoading: false,
      response: {},
    }
  },

  computed: {
    ...mapStores(useOrgStore, useFoundStore),

    isInitial() {
      const q = this.form
      return (
        q.q === null && q.category === null && q.page == 0 && !this.isLoading
      )
    },

    hasResults() {
      return this.found.items.length > 0
    },

    searchBoxClass() {
      if (this.isInitial) {
        return ['simple-search']
      }
      return ['detail-search']
    },
  },

  mounted() {
    if (!utils.isEmpty(this.$route.query)) {
      Object.assign(this.form, this.$route.query)
      this.submit(this.form)
    }
  },

  methods: {
    thumbUrl(item) {
      return item?.thumbUrl || item.image
    },

    openItem(item) {
      this.openModal({
        component: ItemViewModal,
        parent: this.$el,
        hasModalCard: true,
        fullScreen: this.isMobile,
        scroll: 'keep',
        props: {
          item: item,
          org: this.organization.org,
        },
      })
    },

    clear() {
      this.$router.push({ path: '/' })
    },

    updatePage(page) {
      console.debug(`change to: ${page} page ${page - 1}`)
      if (page <= this.found.searchPagination.totalPages + 1) {
        this.form.page = page - 1
        this.$router.push({ name: 'FoundItems', query: this.form })
        this.submit(this.form)
      }
    },

    searchUpdate(form) {
      utils.log({ name: 'FoundItems', query: form })
      this.$router.push({ name: 'FoundItems', query: form })
      this.submit(form)
    },

    submit(form) {
      this.isLoading = true
      this.found
        .searchItems(form)
        .then((result) => {
          this.response = result
        })
        .catch((error) => {
          console.debug(error)
          utils.toast(error.data.messages || error, 'danger')
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<template>
  <div class="has-text-centered" style="width: 100%">
    <ItemSearch
      v-model="form"
      :loading="isLoading"
      title="Find Your Item"
      @submit="searchUpdate"
      @clear="clear"
    />
    <div class="container">
      <div
        data-cy="searchResults"
        class="columns is-mobile is-multiline is-centered"
      >
        <template v-for="item in found.items" :key="item.key">
          <div class="column is-one-quarter-tablet is-half-mobile">
            <div class="box">
              <div class="has-text-centered">{{ item.pretty_type }}</div>
              <div class="image bottom-gap-sm">
                <img
                  :src="thumbUrl(item)"
                  style="
                    max-height: 14rem;
                    min-height: 8rem;
                    width: auto;
                    margin: auto;
                  "
                />
              </div>
              <o-field v-if="item.name" label="Name" horizontal>
                {{ item.name.split(' ').splice(-1)[0] }}
              </o-field>
              <o-field v-if="item.locker" label="Locker" horizontal>
                {{ item.locker }}
              </o-field>
              <o-field v-if="item.model" label="Model" horizontal>
                {{ item.model }}
              </o-field>
              <div class="has-text-centered claim-button">
                <o-button variant="primary" outlined @click="openItem(item)">
                  Claim
                </o-button>
              </div>
              <div class="content">
                <dl>
                  <dt><strong></strong></dt>
                  <dd></dd>
                </dl>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div
        v-if="!isInitial && found.items.length == 0"
        class="container has-text-centered my-6"
      >
        <p v-cloak v-if="!isInitial">Nothing Found</p>
      </div>
      <o-pagination
        v-if="found.items.length > 0"
        rootClass="mx-2"
        :current="found.searchPagination.page + 1"
        order="centered"
        :per-page="found.searchPagination.perPage"
        rounded
        simple
        size="small"
        :total="found.searchPagination.results"
        @change="updatePage($event)"
      >
      </o-pagination>
      <div v-if="organization.hasLiffAlerts" class="columns">
        <div class="column">
          <o-button
            data-cy="lossReportBtn"
            :variant="hasResults ? '' : 'primary'"
            outlined
            @click="$router.push('/loss')"
          >
            Create Loss Report
          </o-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.field.has-addons.has-addons-centered {
  justify-content: center;
  @media (max-width: 500px) {
    justify-content: flex-start;
  }
}
</style>

<style scoped lang="scss">
.simple-search {
  transition: 500ms linear all;
  padding: 3rem 1.5rem;
}

.detail-search {
  transition: 500ms linear all;
  padding: 0;
  margin-bottom: 3rem;
}

.claim-button {
  margin-top: 1rem;
}
</style>
