<script setup>
import ClickToCopy from '@/components/ClickToCopy.vue'
</script>

<script>
import { mapStores } from 'pinia'

import { useFoundStore } from '../store.js'
import utils from '@/utils.js'

export default {
  name: 'ItemClaimComplete',

  components: {},

  props: {},

  data() {
    return {
      claim: null,
      form: null,
      item: null,
    }
  },

  computed: {
    ...mapStores(useFoundStore),

    isMobile: utils.isMobile,
  },

  mounted() {
    let { claim, form, item } = this.found.claimResponse
    this.claim = claim
    if (this.claim === undefined) {
      this.$router.go(-1)
    }
    this.item = item
    this.form = form
  },

  methods: {},
}
</script>

<template>
  <div class="columns is-centered m-3" style="width: 100%">
    <div class="column is-four-fifths-desktop is-three-fifths-widescreen">
      <div class="columns" style="width: 100%">
        <div class="column is-one-third">
          <img v-if="item" :src="item.imageUrl" />
        </div>
        <div class="column">
          <section class="content">
            <h1>Claim Complete</h1>
            <article class="message is-warning">
              <div class="message-body">
                We sent you an email! All communication will be by email; make
                sure to check your spam folder and mark any relevant messages as
                <b>not spam</b>.
              </div>
            </article>
            <p>Thank you for submitting a claim.</p>
            <div v-if="form">
              <h3>Your Email</h3>
              <pre>{{ found.claimResponse.form.email }}</pre>
            </div>
            <p>
              The Lost and Found staff will process your claim and update you on
              next steps as soon as possible.
            </p>
            <div v-if="claim">
              <h3>Claim Key</h3>
              <ClickToCopy v-if="isMobile" :value="claim.key">
                {{ claim.key.slice(0, 8) }} ...
                {{ claim.key.slice(-8) }}
              </ClickToCopy>
              <p v-else>{{ claim.key }}</p>
            </div>
          </section>
          <section style="margin-top: 2rem">
            <o-field grouped>
              <o-button
                class="control"
                label="Done"
                tag="router-link"
                to="/search"
                type="is-primary" />
            </o-field>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>
