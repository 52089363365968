<script>
import { mapStores } from 'pinia'

import { useOrgStore } from '@/stores'
import { useFoundStore } from '../store.js'

import ItemSearch from '../components/ItemSearch.vue'
import ZoomedImage from '@/components/ZoomedImage.vue'
import liffapi from '@/liffapi.js'

export default {
  name: 'OrgIndex',

  components: {
    ItemSearch,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let org = vm.organization
      if (!org) return next()

      let requireActive = vm.$route.meta.requireActive
      if (requireActive && !org.displayOptions.isActive) {
        return next({ name: 'org-closed' })
      } else {
        return next()
      }
    })
  },

  emits: ['input'],

  data() {
    return {
      q: {
        q: null,
        category: null,
        location: null,
        epoch: 'any',
        group: null,
        page: 0,
      },
      items: [],
      response: {},
      stats: null,
    }
  },

  computed: {
    ...mapStores(useOrgStore, useFoundStore),

    orgMessage() {
      return this.organization?.org?.message
    },
  },

  mounted() {
    liffapi.organization.getStats().then((response) => {
      this.stats = response.data
    })
  },

  methods: {
    hourStyle(n) {
      if (n > 0) {
        return 'font-weight: normal'
      }
      return ''
    },

    update(key, value) {
      const ret = { ...this.local, [key]: value }
      this.$emit('input', ret)
      return ret
    },

    doSearch(form) {
      this.$router.push({ path: '/search', query: form })
    },

    clear() {
      this.q = Object.assign(this.q, {
        q: null,
        category: null,
        location: null,
        epoch: 'any',
        page: 0,
      })
    },

    prettyDateTime(dt) {
      dt = new Date(dt)
      const d = dt.toLocaleDateString()
      const t = dt.toLocaleTimeString()
      return `${d} ${t}`
    },

    prettyDate(dt) {
      dt = new Date(dt)
      const d = dt.toLocaleDateString()
      return `${d}`
    },

    time(datetime) {
      const options = {
        hour: 'numeric',
        hour12: true,
      }
      const dt = new Date(datetime)
      const ret = dt.toLocaleTimeString(undefined, options)
      return ret
    },

    daySplit(day) {
      return day?.split(' ') || day || ' '
    },

    zoomMap(imageUrl) {
      this.openModal({
        component: ZoomedImage,
        parent: this.$el,
        fullScreen: this.isMobile,
        scroll: 'keep',
        props: {
          imageUrl: imageUrl,
        },
      })
    },
  },
}
</script>

<template>
  <div class="container" style="">
    <div v-if="organization" class="has-text-centered" style="">
      <section class="section">
        <div class="columns is-mobile is-centered">
          <div class="column">
            <p v-html="orgMessage"></p>
          </div>
        </div>
      </section>

      <ItemSearch
        v-model="q"
        class="mb-5"
        :loading="isLoading"
        hide-tools
        title="Find Your Item"
        @clear="clear"
        @submit="doSearch"
      />

      <p v-if="stats">
        We have returned {{ stats.items_returned }} items and counting.
      </p>

      <div v-if="organization.hasLiffProtect" id="bePrepared">
        <o-button
          href="v/#/protect/wallpaper"
          tag="a"
          size="large"
          variant="primary"
        >
          Be Prepared
        </o-button>
        <h3 class="is-size-7">Protect your phone before it goes missing</h3>
      </div>
      <div id="details">
        <div
          v-if="organization.hasInfo"
          class="has-text-centered content"
          style="margin-bottom: 3rem"
        >
          <h1>Lost and Found Info</h1>
        </div>
        <div class="is-flex is-flex-wrap-wrap is-justify-content-center">
          <div
            v-if="organization.hasValidOperatingHours"
            class="info-col info-hours px-2"
          >
            <div class="has-text-left">
              <div class="">
                <p class="subtitle has-text-grey has-text-centered">Hours of Operation</p>
                <table class="table is-borderless" style="width: 100%">
                  <tr>
                    <th class="has-text-centered">Day</th>
                    <th class="has-text-centered">Hours</th>
                    <th class="has-text-centered">Location</th>
                  </tr>
                  <template
                    v-for="(hours, n) in organization.org.operation_hours"
                    :key="n"
                  >
                    <tr>
                      <th>
                        <template
                          v-for="(s, m) in daySplit(hours.day)"
                          :key="m"
                        >
                          <p :style="hourStyle(m)" style="text-align: left">
                            {{ s }}
                          </p>
                        </template>
                      </th>
                      <td>{{ hours.hours }}</td>
                      <td>{{ hours.location }}</td>
                    </tr>
                    <tr v-if="hours.message" colspan="3">
                      <td colspan="3">
                        <p class="has-text-left">{{ hours.message }}</p>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </div>

          <div v-if="organization.mapUrl" class="info-col px-2">
            <p class="subtitle has-text-grey">Map</p>
            <img
              alt="Venue map"
              :src="organization.mapUrl || '#'"
              style="width: auto; margin: 0 auto"
              @click="zoomMap(organization.mapUrl)"
            />
          </div>
        </div>

        <section class="section">
          <o-button
            tag="a"
            href="https://accounts.liff.app/#/login"
            variant="light"
          >
            Manage your account
          </o-button>
        </section>
      </div>
    </div>
    <div v-else>
      <p>Loading...</p>
    </div>
  </div>
</template>

<style scoped>
#bePrepared {
  margin-top: 3rem;
}

#details {
  margin-top: 3rem;
}

.info-col {
  width: 100%;
}

.info-hours {
  max-width: 50rem;
}
</style>
