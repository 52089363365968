import { createRouter, createWebHashHistory } from 'vue-router'

import FoundItems from './views/FoundItems.vue'
import ItemClaim from './views/ItemClaim.vue'
import ItemClaimComplete from './views/ItemClaimComplete.vue'
import LossReport from '@/components/LossReport.vue'
import OrgClosed from './views/OrgClosed.vue'
import OrgIndex from './views/OrgIndex.vue'

const routes = [
  {
    path: '/',
    name: 'OrgIndex',
    component: OrgIndex,
    meta: {
      title: 'Org Index',
      requiresAuth: true,
    },
  },
  {
    path: '/closed',
    name: 'OrgClosed',
    component: OrgClosed,
    meta: {
      title: 'Org Closed',
    },
  },
  {
    path: '/claim/complete',
    name: 'ClaimComplete',
    component: ItemClaimComplete,
    meta: {
      title: 'Claim Submitted',
    },
  },
  {
    path: '/item/:itemKey/claim',
    name: 'ItemClaim',
    component: ItemClaim,
    meta: {
      title: 'Claim Item',
    },
  },
  {
    path: '/loss',
    name: 'LossReport',
    component: LossReport,
    meta: {
      title: 'Loss Report',
    },
  },
  {
    path: '/search',
    name: 'FoundItems',
    component: FoundItems,
    meta: {
      title: 'Search Found Items',
      requireActive: true,
    },
  },
]

const router = createRouter({
  history: createWebHashHistory(),

  routes: routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

let isInitialized = false

function isInIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

router.beforeEach((to, from, next) => {
  document.title = to?.meta?.title || to?.name || document.title

  if (isInIframe() && isInitialized) {
    let href = `${window.self.location.origin}/#${to.fullPath}`
    window.top.location.href = href
  } else {
    next()
    isInitialized = true
  }
})

export default router
