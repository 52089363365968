<script>
import utils from '@/utils.js'

export default {
  name: 'ClickToCopy',

  components: {},

  props: {
    value: { type: String, default: null },
  },

  data() {
    return {}
  },

  computed: {},

  mounted() {},

  methods: {
    clipboardCopy(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          utils.toast(`Copied: ${text}`, 'info')
        },
        () => {
          utils.toast('Failed copy', 'danger')
        }
      )
    },
  },
}
</script>

<template>
  <a @click="clipboardCopy(value)">
    <o-icon icon="content-copy" size="small" style="pr-1" />
    <slot></slot>
  </a>
</template>

<style></style>
