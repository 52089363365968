<script>
import { mapStores } from 'pinia'
import { useOrgStore } from '@/stores'

export default {
  name: 'OrgClosed',

  components: {},

  data() {
    return {}
  },

  computed: {
    ...mapStores(useOrgStore),
  },

  methods: {},
}
</script>

<template>
  <div class="container" style="">
    <div v-if="organization.org" class="has-text-centered" style="">
      <p>{{ organization.org.message }}</p>
    </div>
    <div v-else>
      <p>Loading...</p>
    </div>
  </div>
</template>

<style scoped>
#details {
  margin-top: 3rem;
}
</style>
