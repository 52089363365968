<script>
import { mapStores } from 'pinia'
import { useOrgStore } from '@/stores'

export default {
  name: 'CategorySelect',

  components: {},

  mixins: [],

  props: {
    modelValue: {
      type: Object,
      default: null,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: 'medium',
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      category: null,
    }
  },

  computed: {
    ...mapStores(useOrgStore),

    categoryData() {
      return this.organization?.categories
    },
  },

  mounted() {
    this.category = this.modelValue
  },

  methods: {
    log(msg) {
      console.log(msg)
    },

    updateCategory(category) {
      if (this.multiple) {
        let ret = [...this.modelValue]
        if (this.modelValue.includes(category)) {
          ret.splice(this.modelValue.indexOf(category), 1)
        } else {
          ret.push(category)
        }
        this.$emit('update:modelValue', ret)
      } else {
        if (this.modelValue === category) {
          this.$emit('update:modelValue', null)
        } else {
          this.$emit('update:modelValue', category)
        }
      }
    },

    isSelected(category) {
      if (this.multiple) {
        return this.modelValue.includes(category)
      }
      return this.modelValue == category
    },
  },
}
</script>

<template>
  <div id="category-select" class="category-select" style="">
    <template v-for="(catVal, catKey) in categoryData" :key="catKey">
      <div class="mr-1 mt-2 has-text-centered">
        <o-button
          v-if="catVal.icon.startsWith('mdi:')"
          size="medium"
          variant="text"
          icon-pack="mdi"
          :aria-label="catVal.label"
          :data-cy="'cat-' + catKey"
          class="category-btn"
          icon-class="my-3"
          :class="{ 'is-primary': isSelected(catKey) }"
          :icon-left="catVal.icon.split(':').at(1)"
          @click="updateCategory(catKey)"
        />
        <o-button
          v-else
          size="medium"
          variant="text"
          icon-pack="liffapp"
          :aria-label="catVal.label"
          :data-cy="'cat-' + catKey"
          class="category-btn"
          icon-class="my-3"
          :class="{ 'is-primary': isSelected(catKey) }"
          :icon-left="catVal.icon"
          @click="updateCategory(catKey)"
        />
        <p class="has-text-centered is-size-7">
          {{ catVal.label }}
        </p>
      </div>
    </template>
  </div>
</template>

<style scoped>
.category-btn {
  margin: auto !important;
}

/* this messes up logitem... */
#category-icons {
  max-width: 22rem;
  overflow-x: scroll;
  @media (min-width: 768px) {
    max-width: 24rem;
  }
  @media (min-width: 1024px) {
    max-width: 22rem;
  }
  @media (min-width: 1216px) {
    max-width: 34rem;
  }
}

.category-select {
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  width: 100%;
  overflow: scroll clip;
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
}

#category-select::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

#category-select::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

button.is-text {
  text-decoration: none !important;
}
</style>
