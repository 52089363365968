<script>
import { mapStores } from 'pinia'
import { useOrgStore } from '@/stores'
import utils from '@/utils.js'

export default {
  name: 'ItemInfo',

  components: {},

  props: {
    item: {
      type: Object,
      default: null,
    },
    hideMeta: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
  },
  emits: [],

  data: () => ({}),

  computed: {
    ...mapStores(useOrgStore),

    categoryData() {
      return this.organization.categories
    },

    isItem() {
      return Boolean(this.item?.key)
    },

    itemHash() {
      return utils.itemHash(this.item)
    },

    meta() {
      return this.categoryData[this.item.category] || {}
    },

    prettyDate() {
      let dt = this.item?.date_created || this.item?.dateCreated
      return utils.prettyDate(dt)
    },

    prettyDateTime() {
      let dt = this.item?.date_created || this.item?.dateCreated
      return utils.prettyDateTime(dt)
    },

    itemData() {
      let data = {}
      if (this.item?.publicData) {
        Object.entries(this.item.publicData).forEach(([k, v]) => {
          let field = this.meta.logFields[k]
          if (v) {
            data[field.label] = v
          }
        })
      } else {
        Object.entries(this.meta.logFields).forEach(([k, field]) => {
          if (k in this.item && this.item[k]) {
            data[field.label] = this.item[k]
          }
        })
      }
      return data
    },
  },

  methods: {},
}
</script>

<template>
  <table
    v-if="isItem"
    class="table is-borderless is-narrow"
    style="background-color: transparent">
    <tbody>
      <template v-if="!hideMeta">
        <tr>
          <th class="is-size-6">Found</th>
          <td>
            <o-tooltip type="is-light" :label="prettyDateTime">
              {{ prettyDate }}
            </o-tooltip>
          </td>
        </tr>
        <tr v-if="item.location">
          <th class="is-size-6">Location</th>
          <td>{{ item.location }}</td>
        </tr>
        <tr>
          <th class="is-size-6">Ref #</th>
          <td>
            <ClickToCopy :value="itemHash">
              <strong>{{ itemHash.slice(0, 4) }}</strong>
              {{ itemHash.slice(4) }}
            </ClickToCopy>
          </td>
        </tr>
      </template>
      <template v-if="!hideDetails">
        <template v-for="(v, k) in itemData" :key="k">
          <tr>
            <th class="is-size-6">{{ k }}</th>
            <td>{{ v }}</td>
          </tr>
        </template>
      </template>
    </tbody>
  </table>
</template>

<style scoped lang="scss">
.modal {
  gap: 1rem;
  justify-content: center;
}
</style>
