import { defineStore } from 'pinia'
import liffapi from '@/liffapi.js'

const useFoundStore = defineStore('found', {
  state: () => ({
    errors: [],
    items: [],
    isLoading: false,
    searchPagination: {
      results: 0,
      page: 0,
      nbPages: 0,
      perPage: 0,
    },
    claimResponse: {},
  }),

  getters: {},

  actions: {
    searchItems(form) {
      // TODO: cache results based on query
      this.isLoading = true
      return liffapi.items
        .search(form)
        .then((response) => {
          let results = response.data
          this.items = results.entities
          Object.assign(this.searchPagination, {
            results: results.nbHits,
            totalPages: results.nbPages,
            page: results.page,
            perPage: results.hitsPerPage,
          })
          return results
        })
        .catch((error) => {
          this.errors.push(error)
          throw error
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
})

export { useFoundStore }
