import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import rollbar from '@/rollbar.js'
import polyfills from '@/polyfills.js'
import LiffModal from '@/components/Modal'

import Oruga from '@oruga-ui/oruga-next'
import { bulmaConfig } from '@oruga-ui/theme-bulma'

import App from './App.vue'
import gtag from '@/gtag.js'
import router from './router'

import '@/assets/base.scss'
import '@/assets/lifficons.css'

polyfills.apply()

const customIconConfig = {
  customIconPacks: {
    liffapp: {
      sizes: {
        default: 'is-size-5',
        small: '',
        medium: 'is-size-3',
        large: 'is-size-1',
      },
      iconPrefix: 'icon-',
      internalIcons: {
        key: 'key-1',
        jewelry: 'jewelry-2',
        creditcard: 'credit-card',
        idcard: 'id-card-1',
        wallet: 'wallet-1',
        other: 'miscellaneous',
      },
    },
  },
}

const pinia = createPinia()
pinia.use(({ store }) => {
  store.$router = markRaw(router)
})

console.debug('BulmaConfig', bulmaConfig)
const app = createApp(App).use(Oruga, { ...bulmaConfig, ...customIconConfig })

app.use(LiffModal)
app.use(pinia)
app.use(router)
gtag.setup(app, router, 'found')

app.config.errorHandler = (err, vm, info) => {
  rollbar.error(err)
  throw err // rethrow
}
app.config.globalProperties.$rollbar = rollbar

app.mount('#found-app')
