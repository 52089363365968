<script>
import { mapStores } from 'pinia'

import { useOrgStore } from '@/stores'
import CategorySelect from '@/components/CategorySelect.vue'
import liffapi from '@/liffapi'
import utils from '@/utils.js'

function defaultForm(org) {
  return {
    name: null,
    email: null,
    agreeToTerms: false,
    howLost: null, // misplaced, stolen, complicated

    categories: [],
    organization: org,
  }
}

export default {
  name: 'LossReport',

  components: { CategorySelect },

  props: {},

  data: () => ({
    isLoading: false,
    isDone: false,
    form: {
      categories: [],
      name: null,
      email: null,
      agreeToTerms: false,
      howLost: null, // misplaced, stolen, complicated
    },
    response: null,
    errors: {
      key: null,
    },
  }),

  computed: {
    ...mapStores(useOrgStore),

    consumerEmail() {
      return this.response?.entity?.email || 'none'
    },

    ok() {
      return (
        this.validateEmail(this.form.email) &&
        this.validateTerms(this.form.agreeToTerms) &&
        this.validateName(this.form.name) &&
        this.form.howLost != null &&
        this.validateCategories(this.form.categories)
      )
    },
  },

  unmounted() {},

  async mounted() {
    this.resetForm()
    this.isDone = false
  },

  methods: {
    doDone() {
      this.$router.push('/')
    },

    submit(form) {
      this.isLoading = true
      return liffapi.lossReports
        .create(form)
        .then((response) => {
          this.isDone = true
          this.response = response
          utils.toast(response.message, 'success')
        })
        .catch((error) => {
          let msg = error.data?.message || error.message
          utils.toast(msg, 'danger')
          throw error
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    resetForm() {
      this.form = defaultForm(this.org)
      Object.assign(this.form, defaultForm(this.organization.key))
    },

    updateDate(val) {
      this.dateOfLoss = val
      this.form.lossInfo.dateOfLoss = val.toISOString()
    },

    classFromError(err) {
      return err ? 'danger' : ''
    },

    validateCategories(val) {
      let err = null
      if (val?.length < 1) {
        err = 'Please select at least one category'
      }
      this.errors = Object.assign(this.errors, { categories: err })
      return err === null
    },

    validateTerms(val) {
      let err = null
      if (!val) {
        err = 'Please agree to the terms and conditions'
      }
      this.errors = Object.assign(this.errors, { terms: err })
      return val
    },

    validateEmail(val) {
      let err = null
      if (!utils.isValidEmail(val)) {
        err = 'Please provide a valid email'
      }
      this.errors = Object.assign(this.errors, {
        email: val === null ? null : err,
      })
      return !err
    },

    validateName(val) {
      let err = null
      if (val === null || val === '') {
        err = 'Please provide your name.'
      } else if (val?.length < 4) {
        err = 'Please provide your full name'
      }
      this.errors = Object.assign(this.errors, { name: err })
      return err === null
    },

    emailAppend(postfix) {
      let n
      if (this.form.email) {
        n = this.form.email.indexOf('@')
        if (n >= 0) {
          this.form.email = this.form.email.slice(0, n) + postfix
        } else {
          this.form.email += postfix
        }
      }

      this.$nextTick(() => {
        this.validateEmail(this.form.email)
      })
    },
  },
}
</script>

<template>
  <main class="section">
    <template v-if="!isDone">
      <o-field class="content" label="What went missing?" :addons="false">
        <CategorySelect
          v-model="form.categories"
          multiple
          @update:modelValue="validateCategories"
        />
        <template #message>
          <p v-if="errors.categories" class="has-text-danger">
            {{ errors.categories }}
          </p>
        </template>
      </o-field>

      <o-field
        label="Your Name"
        horizontal
        :message="errors.name"
        :variant="errors.name ? 'danger' : ''"
      >
        <o-input
          v-model="form.name"
          data-cy="name"
          type="name"
          placeholder="Arthur Dent"
          required
          @blur="validateName(form.name)"
        >
        </o-input>
      </o-field>

      <o-field
        label="Your Email"
        horizontal
        :message="errors.email"
        :variant="errors.email ? 'danger' : ''"
      >
        <o-input
          v-model="form.email"
          data-cy="email"
          type="email"
          placeholder="Your email"
          required
          @blur="validateEmail(form.email)"
        >
        </o-input>
      </o-field>

      <div class="field is-horizontal mb-6">
        <label class="field-label" />
        <div class="field-body">
          <div class="email-btns">
            <o-button
              data-cy="gmailEmailAdd"
              variant="text"
              @click="emailAppend('@gmail.com')"
            >
              @gmail.com
            </o-button>
            <o-button
              data-cy="yahooEmailAdd"
              variant="text"
              @click="emailAppend('@yahoo.com')"
            >
              @yahoo.com
            </o-button>
            <o-button variant="text" @click="emailAppend('@hotmail.com')">
              @hotmail.com
            </o-button>
            <o-button variant="text" @click="emailAppend('@icloud.com')">
              @icloud.com
            </o-button>
          </div>
        </div>
      </div>

      <div class="field is-horizontal mb-6">
        <div class="field-label">
          <label class="label is-size-6">How did you lose your item?</label>
        </div>
        <div class="field-body">
          <div>
            <o-field>
              <o-radio
                v-model="form.howLost"
                data-cy="misplacedRadio"
                name="how-lost"
                native-value="misplaced"
                label="Misplaced"
              />
            </o-field>

            <o-field>
              <o-radio
                v-model="form.howLost"
                data-cy="stolenRadio"
                name="how-lost"
                native-value="stolen"
                label="Stolen"
              />
            </o-field>

            <o-field>
              <o-radio
                v-model="form.howLost"
                data-cy="complicatedRadio"
                name="how-lost"
                native-value="complicated"
                label="It's Complicated"
              />
            </o-field>
          </div>
        </div>
      </div>

      <o-field
        label=""
        horizontal
        :message="errors.terms"
        :variant="errors.terms ? 'danger' : ''"
      >
        <o-checkbox
          v-model="form.agreeToTerms"
          data-cy="terms"
          @update:model-value="validateTerms"
        >
          I agree to the
          <a
            href="https://www.liffhappens.com/terms-of-service/"
            class="has-text-link"
          >
            terms and conditions
          </a>
        </o-checkbox>
      </o-field>
      <o-field label="" horizontal>
        <o-button
          data-cy="submitBtn"
          size="large"
          :disabled="!ok || isLoading"
          :loading="isLoading"
          variant="primary"
          @click="submit(form)"
        >
          Submit
        </o-button>
      </o-field>
    </template>

    <div v-if="isDone" class="content">
      <h3>Successfully submitted!</h3>

      <p>Check your email:</p>

      <p class="email-quote py-4">{{ consumerEmail }}</p>

      <p>
        You will receive important messages about the lost and found process and
        <b>key next steps</b>.
      </p>

      <p>We'll show you how to:</p>

      <ul>
        <li>Check found items</li>
        <li>Add extended information to your report</li>
        <li>Recover from your loss in the mean time</li>
      </ul>

      <div class="is-flex is-justify-content-center">
        <o-button
          data-cy="doneBtn"
          size="large"
          outlined
          variant="primary"
          @click="doDone"
        >
          Done
        </o-button>
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss">
nav.modal-actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.modal-card-body {
  height: calc(100vh - 10rem);
}

.email-quote {
  text-align: center;
  font-size: 1.25rem;
  background-color: #f5f5f5;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
}

.email-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-around;
  width: 100%;
}
</style>
