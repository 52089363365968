<script>
import { mapStores } from 'pinia'
import { useOrgStore } from '@/stores'
// import liffapi from '@/liffapi.js'
// import utils from '@/utils.js'

let debugForm = {
  key: null,
}

export default {
  name: 'ItemSearch',

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let org = vm.organization.org
      if (!org) return next()

      let requireActive = vm.$route.meta.requireActive
      if (requireActive && !org.displayOptions?.isActive) {
        return next({ name: 'org-closed' })
      } else {
        return next()
      }
    })
  },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue', 'submit', 'clear'],

  data() {
    return {
      items: [],
      response: {},
      searchPlaceholder: 'Search for your item',
      searchPlaceholders: [
        'Search...',
        'Search for your item',
        'Try your name',
      ],
    }
  },

  computed: {
    ...mapStores(useOrgStore),

    epochId() {
      return this.organization.org?.itemOptions?.epochId || 'Epoch'
    },

    hasPhoneGroups() {
      return (
        Boolean(this.organization.categories?.phone?.logFields.group) &&
        this.modelValue.category == 'phone'
      )
    },

    initial() {
      const q = this.modelValue
      return q.q === null && q.category === null && q.page == 0
    },

    local() {
      return this.modelValue ? this.modelValue : {}
    },

    searchBoxClass() {
      if (this.initial) {
        return ['simple-search']
      }
      return ['detail-search']
    },

    hasEpochs() {
      let epochs = this.organization.org?.itemOptions?.epochs
      return epochs?.length > 0 && epochs.some((e) => e.enabled)
    },
  },

  mounted() {
    window.setInterval(() => {
      this.rotatePlaceholder()
    }, 5000)
  },

  methods: {
    rotatePlaceholder() {
      let n = this.searchPlaceholders.findIndex(
        (s) => s == this.searchPlaceholder
      )
      this.searchPlaceholder =
        this.searchPlaceholders[(n + 1) % this.searchPlaceholders.length]
    },

    update(key, value) {
      const ret = { ...this.local, [key]: value }
      ret.page = 0
      console.log('update:modelValue', ret, key, value)
      this.$emit('update:modelValue', ret)
      if (key !== 'q') {
        this.$emit('submit', ret)
      }
      return ret
    },

    updateCategory(category) {
      let ret = null
      if (this.modelValue.category == category) {
        ret = this.update('category', null)
      } else {
        ret = { ...this.local, category: category, q: null, group: null }
        ret.page = 0
        this.$emit('update:modelValue', ret)
      }
      this.$emit('submit', ret)
    },
  },
}
</script>

<template>
  <div :class="searchBoxClass" class="has-text-left">
    <div class="container has-text-centered">
      <h1 v-if="initial">Search Inventory</h1>
      <form
        style="margin-bottom: 1rem"
        @submit.prevent="$emit('submit', modelValue)"
      >
        <div class="search-row">
          <div class="field has-addons control">
            <o-input
              class="is-wider"
              data-cy="itemQuery"
              :placeholder="searchPlaceholder"
              :value="local.q"
              type="search"
              @update:modelValue="update('q', $event)"
            />
            <p class="control">
              <o-button
                class="button is-primary"
                data-cy="searchBtn"
                :disabled="loading"
                :loading="loading"
                @click="$emit('submit', modelValue)"
              >
                Search
              </o-button>
            </p>
          </div>
          <p v-if="!initial" class="control is-grouped" style="">
            <o-button
              data-cy="clearBtn"
              variant="primary"
              outlined
              @click="$emit('clear')"
            >
              Clear
            </o-button>
          </p>
        </div>
      </form>
      <div
        label=""
        :addons="false"
        position="is-centered"
        class="categories-wrap"
        style=""
      >
        <div id="category-select" position="is-centered">
          <template
            v-for="(catVal, catKey) in organization.categories"
            :key="catKey"
          >
            <o-button
              v-if="catVal.icon.startsWith('mdi:')"
              icon-pack="mdi"
              :icon-left="catVal.icon.split(':').at(1)"
              size="medium"
              class="category-btn m-1"
              :data-cy="`category-${catKey}`"
              :class="{ 'is-primary': local.category == catKey }"
              @click="updateCategory(catKey)"
            />
            <o-button
              v-else
              size="medium"
              icon-pack="liffapp"
              class="category-btn m-1"
              :data-cy="`category-${catKey}`"
              :class="{ 'is-primary': local.category == catKey }"
              :icon-left="catVal.icon"
              @click="updateCategory(catKey)"
            />
          </template>
        </div>
      </div>
    </div>

    <div class="columns is-mobile is-centered" style="padding-top: 2rem">
      <div v-if="hasPhoneGroups" class="column is-narrow">
        <div class="field is-floating-label">
          <label class="label">Phone Type</label>
          <o-select
            :value="modelValue.group"
            placeholder="Phone Type"
            @update:modelValue="update('group', $event)"
          >
            <option value="any">Any</option>
            <option value="apple">Apple</option>
            <option value="android">Android</option>
            <option value="other">Other</option>
          </o-select>
        </div>
      </div>

      <div v-if="hasEpochs" class="column is-narrow">
        <div class="field is-floating-label">
          <label class="label">{{ epochId }}</label>
          <o-select
            :modelValue="modelValue.epoch"
            :placeholder="epochId"
            @update:modelValue="update('epoch', $event)"
          >
            <option value="any">Any</option>
            <template v-for="epoch in organization.org.itemOptions.epochs">
              <option
                v-if="epoch.enabled"
                :key="epoch.slug"
                :value="epoch.slug"
              >
                {{ epoch.name }}
              </option>
            </template>
          </o-select>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.search-row {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.field.is-floating-label .label {
  position: absolute;
  left: 1em;
  font-size: calc(1rem * 0.75);
  background-color: transparent;
  z-index: 5;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 2em);
  overflow: hidden;
}
.field.is-floating-label {
  position: relative;
}
button.is-text {
  text-decoration: none !important;
}

.categories-wrap {
  overflow-x: auto;
  padding: 5px;
  @media (max-width: 768px) {
    overflow-x: scroll;
  }
}
</style>
