<script setup>
import ItemInfo from '../components/ItemInfo.vue'
</script>

<script>
import { mapStores } from 'pinia'
import { useOrgStore } from '@/stores'

// import liffapi from '@/liffapi.js'
import utils from '@/utils.js'

let debugForm = {
  key: null,
}

export default {
  name: 'ItemView',

  components: {},

  props: {
    item: {
      type: Object,
      default: null,
    },
  },

  emits: ['close'],

  data: () => ({
    registrationType: null,
    form: {
      key: null,
    },
    errors: {
      key: null,
    },
  }),

  computed: {
    ...mapStores(useOrgStore),

    org() {
      return this.organization.org
    },

    ok() {
      return true
    },
  },

  async mounted() {
    if (import.meta.env.VITE_DEBUG == 'true') {
      this.form = Object.assign({}, debugForm)
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },

    submit(key) {
      this.$router.push(`/item/${key}/claim`)
      this.$emit('close')
    },

    validateForm(form) {
      console.log(form)
      let errs = {
        key: null,
      }

      /*
      if (!utils.isValidName(form.name)) {
        errs.key = ''
      }
      */

      this.errors = Object.assign(this.errors, errs)
    },

    classFromError(err) {
      return err ? 'danger' : ''
    },
  },
}
</script>

<template>
  <div class="modal-card has-text-left" style="width: 100%">
    <header class="modal-card-head">
      <p class="modal-card-title">Found Item</p>
      <button type="button" class="delete" @click="close" />
    </header>

    <section class="modal-card-body m-0" style="">
      <div class="columns" style="">
        <div class="column is-4 is-narrow has-text-centered">
          <img :src="item.image" />
        </div>
        <div class="column">
          <ItemInfo :item="item" />
        </div>
      </div>
    </section>

    <footer class="modal-card-foot">
      <div class="level is-mobile" style="width: 100%">
        <div class="level-left">
          <div class="level-item">
            <img
              :style="org.logoStyle || `max-height: 3rem;`"
              :src="org.logoUrl" />
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <o-button
              class="wide-button"
              label="Claim"
              variant="primary"
              @click="submit(item.key)" />
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<style scoped lang="scss">
.modal {
  gap: 1rem;
  justify-content: center;
}
</style>
