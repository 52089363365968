<script setup>
import ItemInfo from '../components/ItemInfo.vue'
</script>

<script>
import { mapStores } from 'pinia'
import { useOrgStore } from '@/stores'
import { useFoundStore } from '../store.js'

import liffapi from '@/liffapi.js'
import utils from '@/utils.js'

import OrgMapLocationPicker from '../components/OrgMapLocationPicker.vue'

export default {
  name: 'ItemClaim',

  components: {},

  props: {},

  emits: ['complete'],

  data() {
    return {
      errors: {
        name: '',
        email: '',
        phoneNumber: null,
      },
      form: {
        name: null,
        email: null,
        phoneNumber: null,
        item: null,
        howLost: null,
        dateOfLoss: null,
        notes: null,
        agreeToTerms: false,
        lossInfo: null,
        itemDetails: {},
      },
      isLoading: false,
      item: {},
      step: 1,
    }
  },

  computed: {
    ...mapStores(useOrgStore, useFoundStore),

    categoryData() {
      return this.organization.categories
    },

    meta() {
      return this.categoryData[this.item.category] || {}
    },

    ok() {
      let ret =
        (this.step == 1 &&
          utils.isValidName(this.form.name) &&
          utils.isValidEmail(this.form.email) &&
          this.form.agreeToTerms) ||
        (this.step == 2 && true) || // if imei and org.type == 'festival'
        this.step == 3 /* && valid loss info || skip */
      return ret
    },

    imageUrl() {
      let ret
      if (this.item.imageUrl) {
        return this.item.imageUrl
      } else {
        ret = this.category ? this.category.defaultImage : ''
        return ret
      }
    },
  },

  watch: {
    step(val) {
      this.$refs.nav.scrollIntoView(true)
    },
  },

  mounted() {
    // TODO: load the item?
    let itemKey = this.$route.params.itemKey
    liffapi.items.get(itemKey).then((response) => {
      this.item = response.data
      this.form.category = this.item.category
      this.form.item = itemKey
    })
  },

  methods: {
    clear() {
      Object.assign(this.form, {
        name: '',
        email: '',
        phoneNumber: null,
        item: null,
        howLost: null,
        dateOfLoss: null,
        notes: null,
        agreeToTerms: false,
        lossInfo: null,
        itemDetails: {},
      })
    },

    fieldIcon(key, val) {
      if (key == 'imei') {
        let normalizeImei = val?.replace(/\D/g, '')
        return utils.isValidIMEI(normalizeImei) ? 'check' : null
      }
      return null
    },

    itemHash: utils.itemHash,
    prettyDate: utils.prettyDate,

    openPinDrop() {
      this.openModal({
        component: OrgMapLocationPicker,
        parent: this.$el,
        events: {
          'pin-dropped': (lossInfo) => (this.form.lossInfo = lossInfo),
        },
      })
    },

    submit(form) {
      let item = this.item
      this.form.item = item.key
      this.form.category = item.category
      this.isLoading = true
      liffapi.items
        .createClaim(form.item, form)
        .then((response) => {
          this.found.claimResponse = {
            response: response,
            claim: response.entity,
            form: form,
            item: this.item,
          }
          this.$router.push({ name: 'ClaimComplete' })
        })
        .catch((error) => {
          console.error(error)
          if (error?.data?.errors) {
            this.step = 1
            this.errors = Object.assign(this.errors, error.data.errors)
          }
          utils.toast(error?.data?.message || error, 'danger')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    validateName(val) {
      let err = null
      if (val === null || val === '') {
        err = 'Please provide your name.'
      } else if (val?.length < 4) {
        err = 'Please provide your full name'
      }
      this.errors = Object.assign(this.errors, { name: err })
    },

    validatePhone(val) {
      let err = null
      if (utils.isValidPhoneNumber(val)) {
        this.errors = Object.assign(this.errors, { phoneNumber: err })
        return
      }
      if (val === null || val === '') {
        err = 'Please enter a value.'
      } else {
        err = 'Try adding a country code (e.g. +1)'
      }
      this.errors = Object.assign(this.errors, { phoneNumber: err })
    },
  },
}
</script>

<template>
  <div class="columns is-centered m-3" style="width: 100%">
    <div class="column is-four-fifths-desktop is-three-fifths-widescreen">
      <div class="columns" style="width: 100%">
        <div v-if="item" class="column content is-one-third">
          <h2 style="text-transform: capitalize">{{ item.category }}</h2>

          <img :src="imageUrl" />

          <ItemInfo :item="item" hideDetails />
        </div>

        <div class="column">
          <section v-if="step == 1" class="">
            <o-field label="Your Name" :variant="errors.name ? 'danger' : ''">
              <o-input
                v-model="form.name"
                data-cy="name"
                expanded
                class="is-wider"
                type="name"
                placeholder="Arthur Dent"
                required
                @blur="validateName(form.name)" />
              <template #message>
                <span v-if="errors.name">{{ errors.name }}</span>
                <span v-else>Full name</span>
              </template>
            </o-field>
            <o-field label="Your Email" :variant="errors.email ? 'danger' : ''">
              <o-input
                v-model="form.email"
                data-cy="email"
                expanded
                class="is-wider"
                type="email"
                placeholder="Your email"
                required />
              <template v-if="errors.email" #message>
                <span>{{ errors.email }}</span>
              </template>
            </o-field>
            <o-field
              label="Your Phone Number"
              :message="errors.phoneNumber"
              :variant="errors.phoneNumber ? 'danger' : ''">
              <o-input
                v-model="form.phoneNumber"
                data-cy="phone"
                expanded
                class="is-wider"
                type="tel"
                placeholder="(312) 555-1234"
                @blur="validatePhone(form.phoneNumber)" />
            </o-field>
            <o-field>
              <o-checkbox v-model="form.agreeToTerms" data-cy="terms"
                >I agree to
                <a
                  href="https://www.liffhappens.com/terms-of-service/"
                  target="_blank"
                  >Terms and Conditions</a
                >
              </o-checkbox>
            </o-field>
          </section>

          <section v-if="step == 2" class="">
            <div class="columns">
              <ItemInfo :item="item" hideMeta class="column is-half" />

              <div class="column">
                <template v-for="(field, k) in meta.claimFields" :key="k">
                  <o-field
                    :message="field.helperText"
                    :label="field.label"
                    grouped>
                    <o-input
                      v-if="field.template == '#template_field'"
                      v-model="form.itemDetails[k]"
                      :data-cy="k"
                      expanded
                      :icon-right="fieldIcon(k, form.itemDetails[k])"
                      :placeholder="field.placeholder">
                    </o-input>
                    <o-input
                      v-if="field.template == '#template_notes'"
                      v-model="form.itemDetails[k]"
                      :data-cy="k"
                      expanded
                      type="textarea"
                      :placeholder="field.placeholder">
                    </o-input>
                    <p v-if="field.name == 'imei'" class="control">
                      <a
                        class="button is-link is-outlined"
                        href="https://www.liffhappens.com/imei-and-me/"
                        target="_blank">
                        ?
                      </a>
                    </p>
                  </o-field>
                </template>
              </div>
            </div>
          </section>

          <section v-if="step == 3" class="">
            <div class="columns">
              <ItemInfo :item="item" hideMeta class="column is-half" />

              <div class="column is-half">
                <o-field label="When did your loss occur?">
                  <o-datetimepicker
                    v-model="form.dateOfLoss"
                      data-cy="dateOfLoss"
                    class="has-text-centered"
                    placeholder="Click to select..."
                    icon="calendar-today"
                    :timepicker="{ incrementMinutes: 15 }">
                  </o-datetimepicker>
                </o-field>

                <o-field label="Where did your loss occur">
                  <o-button data-cy="mapBtn" @click="openPinDrop">Map</o-button>
                  <o-icon
                    v-if="form.lossInfo"
                    icon="check"
                    size="is-medium"
                    variant="success">
                  </o-icon>
                </o-field>
                <o-field label="How did you lose your item?"> </o-field>
                <o-field>
                  <o-radio
                    v-model="form.howLost"
                    data-cy="misplacedRadio"
                    name="how-lost"
                    native-value="misplaced">
                    Misplaced
                  </o-radio>
                </o-field>
                <o-field>
                  <o-radio
                    v-model="form.howLost"
                    data-cy="stolenRadio"
                    name="how-lost"
                    native-value="stolen">
                    Stolen
                  </o-radio>
                </o-field>
                <o-field>
                  <o-radio
                    v-model="form.howLost"
                    data-cy="complicatedRadio"
                    name="how-lost"
                    native-value="complicated">
                    It's Complicated
                  </o-radio>
                </o-field>
                <o-field label="Notes">
                  <o-input
                    v-model="form.notes"
                    data-cy="lossNotes"
                    type="textarea"
                    rows="2"
                    placeholder="Anything else?">
                  </o-input>
                </o-field>
              </div>
            </div>
          </section>

          <section style="margin-top: 2rem mx-1">
            <nav ref="nav" class="is-flex is-justify-content-flex-end is-gap-1">
              <o-button
                v-if="step > 1"
                label="Back"
                data-cy="backBtn"
                class="control"
                variant="primary is-outlined"
                @click="step--" />
              <o-button
                v-if="step == 3"
                class="control"
                label="Done"
                data-cy="doneBtn"
                :disabled="Boolean(isLoading)"
                :loading="Boolean(isLoading)"
                variant="primary"
                @click="submit(form)" />
              <o-button
                v-else
                data-cy="nextBtn"
                :disabled="!ok"
                label="Next"
                variant="primary"
                @click="step++" />
            </nav>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>
