<script>
import { mapStores } from 'pinia'
import { useOrgStore } from '@/stores'

export default {
  name: 'OrgMapLocationPicker',

  components: {},

  props: {},

  emits: ['close', 'pin-dropped'],

  data() {
    return {
      dropped: false,
      form: {
        locationX: 0,
        mapX: 0,
        locationY: 0,
        mapY: 0,
        done: false,
      },
    }
  },

  computed: {
    ...mapStores(useOrgStore),

    ok() {
      return false
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },
    submit(form) {
      console.log(form)
    },

    clear() {
      Object.assign(this.form, {
        locationX: 0,
        mapX: 0,
        locationY: 0,
        mapY: 0,
        done: false,
      })
    },

    updateLocation(e) {
      const marker = this.$refs.marker.$el
      const map = this.$refs.map
      console.log(e)
      this.dropped = true

      this.$nextTick(() => {
        if (e.target.tagName == 'IMG') {
          marker.style.left = `${e.offsetX - this.m_x()}px`
          marker.style.top = `${e.offsetY - this.m_y()}px`
          this.form.locationX = e.offsetX
          this.form.locationY = e.offsetY
        } else {
          this.form.locationX = marker.offsetLeft + e.offsetX
          this.form.locationY = marker.offsetTop + e.offsetY
          marker.style.left = `${this.form.locationX - this.m_x()}px`
          marker.style.top = `${this.form.locationY - this.m_y()}px`
        }
        this.form.done = true
        this.form.mapX = map.offsetWidth
        this.form.mapY = map.offsetHeight
        console.log(this.form)
        this.$emit('pin-dropped', this.form)
      })
    },

    m_x() {
      return this.$refs.marker.$el.offsetWidth / 2
    },

    m_y() {
      return this.$refs.marker.$el.offsetHeight
    },

    x() {
      const _x =
        this.$refs.marker &&
        this.form.locationX - this.$refs.marker.$el.offsetWidth / 2
      return _x || 0
    },

    y() {
      const _y =
        this.$refs.marker &&
        this.form.locationY - this.$refs.marker.$el.offsetHeight
      return _y || 0
    },
  },
}
</script>

<template>
  <div class="box" style="">
    <p class="has-text-centered">
      (click the map if you think you know where you and your item parted ways)
    </p>
    <figure ref="map" class="image" style="" @click="updateLocation($event)">
      <o-icon
        v-show="dropped"
        ref="marker"
        class="marker"
        custom-class="marker"
        icon="map-marker"
        variant="info"
        size="is-large"
        :style="{ top: `${y()}px`, left: `${x()}px` }"
      />
      <img
        v-if="organization"
        :src="organization.mapUrl"
        style="opacity: 0.8"
      />
    </figure>
    <div class="level">
      <div class="level-item">
        <a data-cy="cancelBtn" class="button" @click="close">Cancel</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.marker {
  position: absolute;
  z-index: 2;
  text-shadow: 3px 3px #000000;
  height: auto;
  width: auto;
}
</style>
