<script>
import { mapStores } from 'pinia'
import { useOrgStore } from '@/stores'

export default {
  name: 'FoundNav',

  props: {
    user: { type: Object, default: null },
  },

  computed: {
    ...mapStores(useOrgStore),
  },

  methods: {
    isInIframe() {
      try {
        return window.self !== window.top
      } catch (e) {
        return true
      }
    },
  },
}
</script>

<template>
  <div
    v-cloak
    v-if="!isInIframe()"
    class="hero-header"
    style="padding-top: 0.5rem">
    <nav v-if="organization.org" class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <figure
            class="image is-3x2"
            :style="organization.org.logoStyle || 'width:6rem;'">
            <a class="navbar-item" href="/" style="padding-left: 0.5rem">
              <template v-if="organization.org.logoUrl">
                <img :src="organization.org.logoUrl" alt="Organization Logo" style="max-height: 3rem" />
              </template>
              <template v-else>
                <router-link to="/">LOGO</router-link>
              </template>
            </a>
          </figure>
        </div>
      </div>
      <div class="level-item" style="">
        <h3 class="liff-title title has-text-grey">Lost & Found</h3>
      </div>
      <div class="level-right">
        <div class="level-item"></div>
      </div>
    </nav>
  </div>
</template>

<style scoped lang="scss">
.liff-title {
  @media (max-width: 768px) {
    display: none;
  }
}
</style>
