<script setup>
import { mapStores } from 'pinia'

import FoundNav from './components/FoundNav.vue'
import LiffFooter from '@/components/LiffFooter.vue'
import { useOrgStore } from '@/stores'
</script>

<script>
import liffapi from '@/liffapi.js'

export default {
  name: 'LiffApp',

  data: () => ({
    notifications: [],
    version: __COMMIT_HASH__,
  }),

  computed: {
    ...mapStores(useOrgStore),
  },

  created() {
    this.organization.loadOrg()
    this.organization.loadCategories()
  },

  async mounted() {},

  methods: {
    info(msg) {
      this.$rollbar.info(msg)
    },
  },
}
</script>

<template>
  <main class="hero is-fullheight">
    <FoundNav />

    <router-view style="" />

    <LiffFooter class="mt-6" />
  </main>
</template>

<style>
@import '@mdi/font/css/materialdesignicons.css';

.hero.is-fullheight {
  min-height: 100vh;
}
</style>
